import React from 'react'

class SideNav extends React.Component {
    render() {
        return (
            <div className={this.props.ClassName} style={this.props.ObjectDynamicStyle}>
                {this.props.Children.map(c => (
                    <a className={c.ClassName} style={c.DynamicStyle}>{c.Value}</a>
                ))}
            </div>
        )
    }
}

export default SideNav