import './App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import Section from "./components/section"
import Collapsible from './components/objects/collapsible'

function App() {
  const Sections = [{
    SectionCode: "home",
    ClassName: "",
    DynamicStyle: { backgroundImage: 'url("/images/maxwax_40.png")', backgroundSize: "cover" },
    Panels: [{
      PanelCode: "NavHome",
      ClassName: "col-md-6",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "",
        DynamicStyle: {},
        Objects: [{
          ObjectId: "1",
          ObjectType: 'img',
          ObjectSrc: '/images/MXLogo.png', //For Image Only
          ClassName: "",
          ObjectDynamicStyle: {
            height: "250px", marginLeft: "30px"
          }
        }
          , {
          ObjectId: "2",
          ObjectType: "sideNav",
          ObjectSrc: "",
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {
            marginTop: "8%", marginLeft: "12%", marginBottom: "0px", marginRight: "12%"
          },
          Children: [{
            Value: "HOME",
            ClassName: "hover-underline-animation",
            DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
          }
            , {
            Value: "ABOUT US",
            ClassName: "hover-underline-animation",
            DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
          }
            , {
            Value: "SERVICES",
            ClassName: "hover-underline-animation",
            DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
          }
            , {
            Value: "IN STORE PRODUCTS",
            ClassName: "hover-underline-animation",
            DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
          }
            , {
            Value: "WHERE TO FIND US",
            ClassName: "hover-underline-animation",
            DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
          }
          ]
        }
          , {
          ObjectId: "3",
          ObjectType: "button",
          ObjectSrc: "",
          ClassName: "",
          ObjectText: "BOOK AN APPOINMENT",
          ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "white", fontSize: "20px", marginTop: "30px", marginLeft: "12%", borderWidth: "2px", width: "50%" }
        }
        ]
      }]
    }
      , {
      PanelCode: "SecondHome",
      ClassName: "col-md-6",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "skip3Container",
        DynamicStyle: { backgroundColor: "#cf7b3a", paddingTop: "30px" }, //style="background-color:#cf7b3a; padding: 0px; padding-top: 30px;"
        Objects: [{
          ObjectId: "1",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Smooth skin",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "60px"
          }
        },
        {
          ObjectId: "2",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "made easy",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "60px"
          }
        },
        {
          ObjectId: "3",
          ObjectType: "button",
          ObjectSrc: "",
          ClassName: "centerTest",
          ObjectText: "SHOP NOW",
          ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "white", display: "block", color: "white", fontSize: "20px", borderWidth: "2px", width: "50%" }
        }
        ]
      }]
    }
    ]
  },
  {
    SectionCode: "aboutus",
    ClassName: "",
    DynamicStyle: { backgroundImage: 'url("/images/maxwax_13.png")', backgroundSize: "cover" },
    Panels: [{
      PanelCode: "FirstAboutUs",
      ClassName: "col-md-6",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "",
        DynamicStyle: {},
        Objects: [{}]
      }]
    }
      , {
      PanelCode: "SecondAboutUs",
      ClassName: "col-md-6",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "",
        DynamicStyle: { backgroundColor: "#cf7b3a", height: "70%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
        Objects: [{
          ObjectId: "1",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Why Maxwax?",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "60px"
          }
        },
        {
          ObjectId: "2",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "At Maxwax Studio, we love EVERYBODY. Love yourself while looking and feeling",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "15px", margin: "0px"
          }
        },
        {
          ObjectId: "3",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "#SexyInYourOwnSkin",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "15px"
          }
        },
        {
          ObjectId: "4",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Wheter you're petite or tall, size XL or small - Every BODY is invited to join the fun!",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "15px"
          }
        }
          , {
          ObjectId: "5",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Maxwax studio envisions a totally new concept in grooming and style-one where our clients can expect the highest level of expertise and hygiene standards, in a place where they can feel comfortable in any shape, size, skin tone and texture they have.",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "15px", margin: "0px"
          }
        }
          , {
          ObjectId: "6",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "We max out the confidence in you!",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "15px"
          }
        }
          , {
          ObjectId: "6",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Our team of aestheticians is dedicated to deliver the best in terms of waxing service and style. We provide waxing and brow grooming services that is fit for any size, lifestyle or any way you want. Most of all, we provide results that are completely on-point, thanks to our inherent understanding of how today's trens can work for your personal style and way of life.",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "15px"

          }
        },
        {
          ObjectId: "7",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Remember: some beauty regimens are best left to experts",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "15px"

          }
        }
          , {
          ObjectId: "8",
          ObjectType: "button",
          ObjectSrc: "",
          ClassName: "centerTest",
          ObjectText: "THE MAXWAX BRAZILIAN EXPERIENCE",
          ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "white", display: "block", color: "white", fontSize: "20px", borderWidth: "2px", width: "50%" }
        }
        ]
      },
      {
        SubPanelCode: 'Panel2',
        ClassName: "",
        DynamicStyle: { backgroundColor: "black", height: "30%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
        Objects: [{
          ObjectId: "1",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Your road to Smooth skin is as easy as 1, 2, 3!",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "50px"
          }
        }
        ]
      }]
    }
    ]
  },
  {
    SectionCode: "steps",
    ClassName: "",
    DynamicStyle: { backgroundImage: 'url("/images/white-fabric.png")', backgroundSize: "cover" },
    Panels: [{
      PanelCode: "FirstSteps",
      ClassName: "col-md-4",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "",
        DynamicStyle: { height: "100%", padding: "20px" },
        Objects: [{
          ObjectId: "1",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "2",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "3",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "4",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Step 1:",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "60px", margin: "0px"
          }
        },
        {
          ObjectId: "5",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "6",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Every BODY is welcome to join the fun",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "30px", margin: "0px"
          }
        },
        {
          ObjectId: "7",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "8",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Choose your desired area for waxing",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "20px", margin: "0px"
          }
        },
        {
          ObjectId: "9",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "10",
          ObjectType: "button",
          ObjectSrc: "",
          ClassName: "centerTest",
          ObjectText: "WAXING SERVICES",
          ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "#cf7b3a", fontSize: "20px", borderWidth: "2px", width: "50%" }
        }

        ]
      }]
    }
      , {
      PanelCode: "SecondSteps",
      ClassName: "col-md-4",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "",
        DynamicStyle: { height: "100%", padding: "20px" },
        Objects: [{
          ObjectId: "1",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "2",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "3",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "4",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Step 2:",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "60px", margin: "0px"
          }
        },
        {
          ObjectId: "5",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "6",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Great things come in packages",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "30px", margin: "0px"
          }
        },
        {
          ObjectId: "7",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "8",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Choose a package to achieve that smooth and sexy skin",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "20px", margin: "0px"
          }
        },
        {
          ObjectId: "9",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "10",
          ObjectType: "button",
          ObjectSrc: "",
          ClassName: "centerTest",
          ObjectText: "PACKAGES",
          ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "#cf7b3a", fontSize: "20px", borderWidth: "2px", width: "50%" }
        }]
      }]
    }
      , {
      PanelCode: "ThirdSteps",
      ClassName: "col-md-4",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "",
        DynamicStyle: { height: "100%", padding: "20px" },
        Objects: [{
          ObjectId: "1",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "2",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "3",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "4",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Step 3:",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "60px", margin: "0px"
          }
        },
        {
          ObjectId: "5",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "6",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Your aftercare at home",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "30px", margin: "0px"
          }
        },
        {
          ObjectId: "7",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "8",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Go home with hair free skin and an after care product to maintain that smooth glow",
          ObjectDynamicStyle: {
            textAlign: "center", color: "#cf7b3a", fontSize: "20px", margin: "0px"
          }
        },
        {
          ObjectId: "9",
          ObjectType: 'br',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {}
        },
        {
          ObjectId: "10",
          ObjectType: "button",
          ObjectSrc: "",
          ClassName: "centerTest",
          ObjectText: "PACKAGES",
          ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "#cf7b3a", fontSize: "20px", borderWidth: "2px", width: "50%" }
        }]
      }]
    }
    ]
  },
  {
    SectionCode: "waxingservices",
    ClassName: "",
    DynamicStyle: { backgroundImage: 'url("/images/maxwax_02.png")', backgroundSize: "cover" },
    Panels: [{
      PanelCode: "FirstAboutUs",
      ClassName: "col-md-6",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "",
        DynamicStyle: {},
        Objects: [{}]
      }]
    }
      , {
      PanelCode: "SecondAboutUs",
      ClassName: "col-md-6",
      DynamicStyle: { padding: "0px" },
      SubPanels: [{
        SubPanelCode: 'Panel1',
        ClassName: "",
        DynamicStyle: { backgroundColor: "#cf7b3a", height: "100%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
        Objects: [{
          ObjectId: "1",
          ObjectType: 'text',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "Waxing Services",
          ObjectDynamicStyle: {
            textAlign: "center", color: "white", fontSize: "60px"
          }
        },
        {
          ObjectId: "2",
          ObjectType: 'accordion',
          ObjectSrc: '', //For Image Only
          ClassName: "",
          ObjectText: "",
          ObjectDynamicStyle: {},
          Children: [{
            Title: "Brazilian",
            Body: [
              {
                Text: "Intimate Bikini Wax",
                Price: "900.00"
              },
              {
                Text: "Full Brazilian Wax",
                Price: "1000.00"
              }
            ],
            ClassName: "",
            DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
          }]
        }
        ]
      }]
    }
    ]
  }
  ]

  return (
    <>
      <Collapsible></Collapsible>
      <div className='content'>
        {Sections.map(s =>
        (<Section
          SectionCode={s.SectionCode}
          ClassName={s.ClassName}
          DynamicStyle={s.DynamicStyle}
          Panels={s.Panels}
        />)
        )}

      </div>
    </>
  );
}

export default App;
