import React from 'react'
import Image from './objects/image'
import SideNav from './objects/sidenav'
import { Button, Col } from 'react-bootstrap'
import Collapsible from './objects/collapsible'


class SubPanel extends React.Component {
    render() {
        return (
            <div id={this.props.SubPanelCode} className={this.props.ClassName} style={this.props.DynamicStyle}>
                {/* {this.props.Objects.map(o => (
                    
                        <div></div>
                ))} */}
                
                {this.props.Objects.map((o) => {
                    //need to change to switch di ko alam bakit ako nag if dito eh
                    if(o.ObjectType === "img"){
                        return <Image 
                                ObjectSrc={o.ObjectSrc}
                                ClassName={o.ClassName}
                                ObjectDynamicStyle={o.ObjectDynamicStyle}
                                />
                    }
                    if(o.ObjectType === "sideNav"){
                        return <SideNav 
                                ObjectSrc={o.ObjectSrc}
                                ClassName={o.ClassName}
                                ObjectDynamicStyle={o.ObjectDynamicStyle}
                                Children={o.Children}
                                />
                    }
                    if(o.ObjectType === "button"){
                        return <Button className={o.ClassName} style={o.ObjectDynamicStyle}>{o.ObjectText}</Button>
                    }
                    if(o.ObjectType === "text"){
                        return <p style={o.ObjectDynamicStyle} className={o.ClassName}>{o.ObjectText}</p>
                    }
                    if(o.ObjectType === "br"){
                        return <br />
                    }
                    if(o.ObjectType === "accordion"){
                        return <Collapsible Children={o.Children}></Collapsible>
                    }
                    return <div></div>
                })}
                
                {/* <Image></Image> */}
                {/* <CollapsibleList></CollapsibleList> */}
                
            </div>
        )
    }
}

export default SubPanel